const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');
const { checkVisible } = require('site/components/functions');

gsap.registerPlugin(ScrollTrigger);

const sections = [];

function pinNavbar() {
    const $bottom = $('.experience-bottom');
    const $navbar = $('.js-anniversary-navbar');

    ScrollTrigger.create({
        trigger: $navbar,
        start: 'top top',
        endTrigger: $bottom,
        end: 'bottom-=80px top',
        pin: true,
        pinSpacing: false,
        onUpdate: () => {
            sections.forEach((section, i) => {
                const isVisible = checkVisible(section.el, -1);
                let prevVisible = false;

                section.visible = isVisible;

                if (i !== 0) {
                    prevVisible = sections[i - 1].visible;
                }

                $(section.navEl).toggleClass('active', isVisible && !prevVisible);
            });
        },
    });
}

function getSections() {
    $('.js-anniversary-navbar .nav-item').each((i, navEl) => {
        const id = $(navEl).attr('href').substring(1);
        const el = document.getElementById(id);

        if (el) {
            sections.push({
                id,
                el,
                navEl,
                visible: false,
            });

            if (i === 0) {
                $(navEl).addClass('active');
            }
        }
    });
}

function handleNavItemClick(e) {
    e.preventDefault();

    const $this = $(e.currentTarget);
    const targetId = $this.attr('href');
    const $target = $(targetId);

    if (!$target || $target.length <= 0) {
        return;
    }

    $('html, body').animate({
        scrollTop: $target.offset().top + $this.data('offset'),
    }, 750);
}

function init() {
    getSections();
    pinNavbar();

    $('.js-anniversary-navbar .nav-item').on('click', handleNavItemClick);
}

module.exports = {
    init,
};
