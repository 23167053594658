const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');
const { breakpoints } = require('site/components/functions');

gsap.registerPlugin(ScrollTrigger);

function animateMountains(target, options) {
    const $mountainBg = target.find('.js-mountain-bg');
    const $mountainMg = target.find('.js-mountain-mg');
    const $mountainFg = target.find('.js-mountain-fg');
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: target,
            scrub: 0.25,
            end: 'center top',
        },
    });

    tl.to($mountainBg, { yPercent: options.yBg, ease: 'none' })
        .to($mountainMg, { yPercent: options.yMg, ease: 'none' }, 0)
        .to($mountainFg, { yPercent: options.yFg, ease: 'none' }, 0);
}

function matchMediaAnimations(target) {
    const mqDesktop = `(min-width: ${breakpoints.xl}px)`;
    const mqTablet = `(min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`;
    const mqMobile = `(max-width: ${breakpoints.lg - 1}px)`;
    const mqObj = {};

    mqObj[mqDesktop] = animateMountains.bind(null, target, {
        yBg: -5,
        yMg: -20,
        yFg: -40,
        yContent: -35,
    });

    mqObj[mqTablet] = animateMountains.bind(null, target, {
        yBg: -5,
        yMg: -15,
        yFg: -35,
        yContent: -15,
    });

    mqObj[mqMobile] = animateMountains.bind(null, target, {
        yBg: -5,
        yMg: -15,
        yFg: -25,
        yContent: -10,
    });

    ScrollTrigger.matchMedia(mqObj);
    target.addClass('animation-initialized');
}

module.exports = function () {
    $('.js-mountains-section').each((i, el) => {
        const $el = $(el);
        if (!$el.hasClass('animation-initialized')) {
            matchMediaAnimations($el);
        }
    });
};
