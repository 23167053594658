function hideLogInButton() {
    const $loginBtnWrapper = $('.js-has-login-btn');
    const $logoutBtn = $('.js-logout-customer');

    if ($loginBtnWrapper.length && $logoutBtn.length) {
        $loginBtnWrapper.find('a').each((i, el) => {
            if (el.text.toLowerCase().includes('login')) {
                el.remove();
            }
        });
    }
}

function bindEvents() {
    $('.js-hero-banner-scroll').on('click', (e) => {
        e.preventDefault();
        const banner = $(e.currentTarget).parent()[0];

        $('html, body').animate({
            scrollTop: banner.scrollHeight,
        }, 800);

        return false;
    });
}

module.exports = {
    bindEvents,
    hideLogInButton,
};
