const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');
const { breakpoints } = require('site/components/functions');
const { animateArrows } = require('site/components/carouselAnimations');

gsap.registerPlugin(ScrollTrigger);

let badgeTl;

function animateBadge(target, mq) {
    const $badge = target.find('.company-highlights__featured-badge .photo-tile-image');

    if (mq === 'small') {
        badgeTl.clear().fromTo($badge, { y: '50%' }, { y: '48.5%', duration: 1, ease: 'none' }, 0);
    } else {
        badgeTl.clear().fromTo($badge, { y: -32 }, { y: -85, duration: 1, ease: 'none' }, 0);
    }
}

function animateElements(target) {
    const $img = target.find('.company-highlights__featured-image .photo-tile-image');
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: $img,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 0.25,
        },
    });

    tl.to($img, { scale: 1.1, duration: 1, ease: 'power1.inOut' }, 0);
}

function animateLeaves(target) {
    const $leaves = target.find('.js-highlight-leaves');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: target,
            start: 'top bottom',
            end: 'top top',
            scrub: 0.25,
        },
    });

    tl.to($leaves, { y: -184, duration: 1, ease: 'none' }, 0);
}

function matchMediaAnimations(target) {
    if (!target.length || target.hasClass('animation-initialized')) {
        return;
    }

    const mqLarge = `(min-width: ${breakpoints.md}px)`;
    const mqSmall = `(max-width: ${breakpoints.md - 1}px)`;
    const mqObj = {};

    badgeTl = gsap.timeline({
        scrollTrigger: {
            trigger: target,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 0.25,
        },
    });

    mqObj[mqSmall] = () => {
        animateBadge(target, 'small');
    };

    mqObj[mqLarge] = () => {
        animateLeaves(target);
        animateBadge(target, 'large');
    };

    mqObj.all = () => {
        animateElements(target);
    };

    ScrollTrigger.matchMedia(mqObj);
    target.addClass('animation-initialized');
}

function initAnimations() {
    $('.js-homepage-highlights').each((i, el) => {
        const $this = $(el);
        if (!$this.hasClass('animation-initialized')) {
            matchMediaAnimations($this);
        }
    });
}

function initRecommendedSlider() {
    $('.js-highlights-slider').each(function () {
        const $this = $(this);

        const slickOptions = {
            mobileFirst: true,
            infinite: true,
            slidesToScroll: 1,
            centerMode: true,
            dots: true,
            slidesToShow: 1,
            centerPadding: '6%',
            autoplay: false,
            prevArrow: '<div class="d-none d-md-block arrow__container arrow__container--prev"><span class="arrow__diamond"></span><button type="button" class="slick-prev">Previous</button></div>',
            nextArrow: '<div class="d-none d-md-block arrow__container arrow__container--next"><span class="arrow__diamond"></span><button type="button" class="slick-next">Next</button></div>',
            responsive: [
                {
                    breakpoint: breakpoints.sm,
                    settings: {
                        centerMode: false,
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: breakpoints.md,
                    settings: {
                        dots: false,
                        centerMode: false,
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: breakpoints.lg,
                    settings: {
                        dots: false,
                        centerMode: false,
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: breakpoints.xl,
                    settings: {
                        dots: false,
                        centerMode: false,
                        slidesToShow: 2,
                    },
                },
            ],
        };

        $this.slick(slickOptions);
        animateArrows($this);
        $this.on('breakpoint', () => {
            animateArrows($this);
        });
    });
}

module.exports = {
    initAnimations,
    initRecommendedSlider,
};
