const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');
const { breakpoints } = require('site/components/functions');

gsap.registerPlugin(ScrollTrigger);

function animateElements(target, options) {
    const $imgs = target.find('.photo-tile-image');
    const $decoration = target.find('.headline-section__decoration');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: target,
            start: options.start,
            end: options.end,
            scrub: 0.25,
        },
    });

    tl.to($imgs, { scale: 1.1, duration: 1, ease: 'power1.inOut' }, 0);

    if ($decoration.length) {
        tl.to($decoration, { y: 45, duration: 1, ease: 'none' }, 0);
    }
}

function matchMediaAnimations(target) {
    if (!target.length || target.hasClass('animation-initialized')) {
        return;
    }

    const mq = `(min-width: ${breakpoints.lg + 1}px)`;
    const mqObj = {};

    mqObj[mq] = animateElements.bind(null, target, {
        start: 'top bottom',
        end: 'bottom top',
    });

    ScrollTrigger.matchMedia(mqObj);
    target.addClass('animation-initialized');
}

module.exports = () => {
    $(window).on('load resize orientationchange', () => {
        $('.js-headline-slider').each(function () {
            const $slider = $(this);
            if ($(window).width() > breakpoints.lg) {
                if ($slider.hasClass('slick-initialized')) {
                    $slider.slick('destroy');
                }
            } else if (!$slider.hasClass('slick-initialized')) {
                $slider.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    mobileFirst: true,
                    dots: true,
                    arrows: false,
                    dotsClass: 'slick-dots',
                });
            }
        });
    });

    $('.js-headline-section').each((i, el) => {
        const $this = $(el);
        if (!$this.hasClass('animation-initialized')) {
            matchMediaAnimations($this);
        }
    });
};
