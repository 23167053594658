function isOnScreen(element, full) {
    let el = element;
    if (typeof jQuery === 'function' && el instanceof jQuery) {
        const { 0: first } = el;
        el = first;
    }

    const rect = el.getBoundingClientRect();

    if (full) {
        return (
            rect.top >= 0
            && rect.left >= 0
            && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
    return (
        rect.top >= 0
        && (rect.top <= (window.innerHeight || document.documentElement.clientHeight)
            || rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
        && rect.left >= 0
    );
}

function asyncEvent(child) {
    const dfd = $.Deferred();

    if (child.hasClass('active')) {
        dfd.resolve();
    }
}

function checkVisible(el, offset) {
    const yOffset = offset || 0;
    const elm = el || this;
    const vpH = $(window).height(); // Viewport Height
    const st = $(window).scrollTop(); // Scroll Top
    const y = $(elm).offset().top + yOffset;
    const elementHeight = $(elm).height();

    return ((y < (vpH + st)) && (y > (st - elementHeight)));
}

function getAsJQuery(el) {
    return el && !(el instanceof jQuery) ? $(el) : el;
}

// Source: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
function uuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
        /[018]/g,
        (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16), // eslint-disable-line no-bitwise,no-mixed-operators
    );
}

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }

    return '';
}

function getSessionIDs() {
    return {
        previousSessionID: window.localStorage.getItem('previousSid'),
        currentSessionID: getCookie('sid'),
    };
}

function getStyle(el, styleProp) {
    if (!el || !styleProp) {
        return;
    }

    // eslint-disable-next-line no-nested-ternary
    return el.currentStyle ? el.currentStyle[styleProp]
        : window.getComputedStyle ? document.defaultView.getComputedStyle(el, null).getPropertyValue(styleProp)
        // eslint-disable-next-line indent
        : false;
}

function stripeUnit(str, unit) {
    if (!(unit && str) || !(typeof unit === 'string' || unit instanceof String)) {
        return str;
    }

    if (str.endsWith(unit)) {
        return parseFloat(str.slice(0, -unit.length));
    }

    return str;
}

module.exports = {
    isOnScreen,
    asyncEvent,
    checkVisible,
    getAsJQuery,
    uuid,
    getCookie,
    getSessionIDs,
    getStyle,
    stripeUnit,
    breakpoints: {
        xs: 0,
        sm: 480,
        md: 759,
        lg: 1024,
        xl: 1365,
        xxl: 1680,
    },
};
