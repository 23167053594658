const { gsap } = require('gsap/dist/gsap');

const arrows = {};

function getSliderId(target) {
    return target.data('cpid') || target.closest('.slider__custom-arrows').data('cpid');
}

function getArrowDirection(target) {
    return target.find('button').is('.slick-prev') ? 'prev' : 'next';
}

function getAllArrows(target) {
    const sliderId = getSliderId(target);
    arrows[sliderId] = {};

    target.find('.slick-arrow').each(function () {
        const $arrow = $(this);
        const direction = getArrowDirection($arrow);
        arrows[sliderId][direction] = {
            target: $arrow,
            diamond: $arrow.find('.arrow__diamond'),
            button: $arrow.find('button'),
        };
    });
}

function handleArrowMouseEnter(e) {
    const $arrow = $(e.currentTarget);
    const sliderId = getSliderId($arrow);
    const direction = getArrowDirection($arrow);

    if (!arrows[sliderId] || !arrows[sliderId][direction]) {
        return;
    }

    if (!arrows[sliderId][direction].tl) {
        arrows[sliderId][direction].tl = gsap.timeline();

        arrows[sliderId][direction].tl
            .to(arrows[sliderId][direction].diamond, {
                scale: 1,
                transformOrigin: 'right top',
                duration: 0.25,
                ease: 'power1.inOut',
            })
            .to(arrows[sliderId][direction].button, { scale: 1.1, duration: 0.25, ease: 'power1.inOut' }, 0);
    } else {
        arrows[sliderId][direction].tl.play();
    }
}

function handleArrowMouseLeave(e) {
    const $arrow = $(e.currentTarget);
    const sliderId = getSliderId($arrow);
    const direction = getArrowDirection($arrow);

    if (!arrows[sliderId] || !arrows[sliderId][direction] || !arrows[sliderId][direction].tl) {
        return;
    }

    arrows[sliderId][direction].tl.reverse();
}

function animateArrows(target) {
    getAllArrows(target);

    $(target).on('mouseenter.playanim', '.slick-arrow', handleArrowMouseEnter);
    $(target).on('mouseleave.reverseanim', '.slick-arrow', handleArrowMouseLeave);
}

module.exports = {
    animateArrows,
};
