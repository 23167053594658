const { template, assign } = require('lodash');
const { getAsJQuery, uuid } = require('./functions');

const tmplDelimiter = /{{([\s\S]+?)}}/g;
const tmplAlert = $('.tmpl-alert').html();
const tmplToast = $('.tmpl-toast').html();
const defaultDelay = 10000;

function getTarget(target) {
    return getAsJQuery(target) || $('body');
}

function createAndAppend(args) {
    assign(args.defaults, args.options, { uuid: uuid() });
    const compiled = template(args.tmpl, { interpolate: tmplDelimiter });
    const html = compiled(args.defaults);

    args.target.append(html);

    return $(`[data-uuid="${args.defaults.uuid}"]`);
}

function createToast(args) {
    const defaults = {
        autohide: 'true',
        delay: args.delay || defaultDelay,
        message: '',
        title: '',
    };

    const $toast = createAndAppend({
        tmpl: tmplToast,
        target: getTarget(args.target),
        defaults,
        options: args,
    });

    $toast.on('hidden.bs.toast', () => {
        $toast.remove();
    });
}

function createAlert(args) {
    const defaults = {
        context: 'alert-warning',
        dismissible: true,
        message: '',
    };

    const $alert = createAndAppend({
        tmpl: tmplAlert,
        target: getTarget(args.target),
        defaults,
        options: args,
    });

    $alert.alert('show')
        .on('closed.bs.alert', () => {
            $alert.alert('dispose');
        });

    if (!args.dismissible) {
        setTimeout(() => {
            $alert.alert('close');
        }, args.delay || defaultDelay);
    }
}

module.exports = {
    alert: createAlert,
    toast: createToast,
};
