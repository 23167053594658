const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');
const { breakpoints } = require('site/components/functions');

gsap.registerPlugin(ScrollTrigger);

function handleClickListItem(e) {
    const $target = $(e.currentTarget);
    const $description = $target.find('.js-list-item-description');

    $target.toggleClass('active');
    $description.slideToggle();
}

function resetListItems() {
    $('.js-list-item').removeClass('active');
    $('.js-list-item-description').show();
}

function animateListItem(target) {
    gsap.to(target, {
        xPercent: -100,
        opacity: 1,
        ease: 'none',
        scrollTrigger: {
            trigger: target,
            scrub: true,
            once: true,
            end: '30% center',
        },
    });
}

function animateImage(target) {
    const $imgs = target.find('.list-section__image');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: target,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 0.25,
        },
    });

    tl.to($imgs, { scale: 1.1, duration: 1, ease: 'power1.inOut' }, 0);
}

function animateElements(target) {
    const mqDesktop = `(min-width: ${breakpoints.xl}px)`;
    const mqObj = {};

    mqObj[mqDesktop] = function () {
        animateListItem(target.find('.js-list-item'));
    };

    mqObj.all = animateImage.bind(null, target);

    ScrollTrigger.matchMedia(mqObj);
    target.addClass('animation-initialized');
}

function init() {
    const $listItems = $('.js-list-item');

    $(window).on('load resize orientationchange', () => {
        $listItems.off('click');

        if ($(window).width() < breakpoints.xl) {
            $listItems.on('click', handleClickListItem);
        } else {
            resetListItems();
        }
    });

    $('.js-headline-and-list').each((i, el) => {
        const $el = $(el);
        if (!$el.hasClass('animation-initialized')) {
            animateElements($el);
        }
    });
}

module.exports = {
    init,
};
