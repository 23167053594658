const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');
const { breakpoints } = require('site/components/functions');

gsap.registerPlugin(ScrollTrigger);

function bindEvents() {
    $('.membership-tile').on('click', function (e) {
        $('.membership-tile').removeClass('active');
        $(this).toggleClass('active');
        e.stopPropagation();
    });

    $(document).on('click', (e) => {
        if ($(e.target).is('.membership-tile') === false) {
            $('.membership-tile').removeClass('active');
        }
    });
}

function animateTilesTablet(target, isLeft) {
    gsap.to(target, {
        xPercent: isLeft ? 100 : -100,
        opacity: 1,
        ease: 'none',
        scrollTrigger: {
            trigger: target,
            scrub: true,
            once: true,
            start: isLeft ? 'top bottom' : 'top 75%',
            end: '30% center',
        },
    });
}

function animateTilesDesktop(target) {
    gsap.to(target, {
        xPercent: -100,
        opacity: 1,
        ease: 'none',
        scrollTrigger: {
            trigger: target,
            scrub: true,
            once: true,
            end: '30% center',
        },
    });
}

function animateElements(target) {
    const mqTablet = `(min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`;
    const mqDesktop = `(min-width: ${breakpoints.xl}px)`;
    const mqObj = {};
    const isLeft = target.parent().index() % 2 === 0;

    target.addClass(isLeft ? 'animate-from-left' : 'animate-from-right');

    // Tablet and larger
    mqObj[mqTablet] = function () {
        animateTilesTablet(target, isLeft);
    };

    // Desktop and larger
    mqObj[mqDesktop] = function () {
        animateTilesDesktop(target);
    };

    ScrollTrigger.matchMedia(mqObj);
    target.addClass('animation-initialized');
}

module.exports = () => {
    bindEvents();

    $('.membership-tile').each((i, el) => {
        const $this = $(el);
        if ($this.hasClass('animation-enabled') && !$this.hasClass('animation-initialized')) {
            animateElements($this);
        }
    });
};
