const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');

gsap.registerPlugin(ScrollTrigger);

function animateElements(target) {
    const $imgs = target.find('img');
    const $decoration = target.find('.our-vineyards-details__decoration');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: target,
            start: 'top bottom',
            end: 'bottom bottom',
            scrub: 0.25,
        },
    });

    tl.to($imgs, { scale: 1.1, duration: 1, ease: 'power1.inOut' }, 0);

    if ($decoration.length) {
        tl.fromTo($decoration, { y: '-50%' }, { y: '-42.5%', duration: 1, ease: 'none' }, 0);
    }
}

function matchMediaAnimations(target) {
    if (!target.length || target.hasClass('animation-initialized')) {
        return;
    }

    const mqObj = {};

    mqObj.all = animateElements.bind(null, target);

    ScrollTrigger.matchMedia(mqObj);
    target.addClass('animation-initialized');
}

module.exports = () => {
    $('.js-vineyards-details').each((i, el) => {
        const $this = $(el);
        if (!$this.hasClass('animation-initialized')) {
            matchMediaAnimations($this);
        }
    });
};
