const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');
const { breakpoints } = require('site/components/functions');
const { alert } = require('site/components/alerts');

gsap.registerPlugin(ScrollTrigger);

function pinVineyardHeading(offset) {
    const $heading = $('.js-vineyard-heading');
    const $lastVineyard = $('.js-vineyard-items').children().last();

    ScrollTrigger.create({
        trigger: $heading,
        start: `top-=${offset}px top`,
        endTrigger: $lastVineyard,
        end: `top-=${offset}px top`,
        pin: true,
        pinSpacing: true,
    });
}

function handleForms() {
    $('.js-submit-anniversary-form').on('submit', function (e) {
        const form = $(this);
        e.preventDefault();

        const url = form.attr('action');
        form.spinner().start();
        const errorMsg = { message: 'For technical reasons, your request could not be handled properly at this time. We apologize for any inconvenience.' };

        $.ajax({
            url,
            type: 'POST',
            dataType: 'json',
            data: form.serialize(),
            success: (data) => {
                form.spinner().stop();
                if (data.success) {
                    form[0].reset();
                    alert({
                        context: 'alert-success',
                        dismissible: false,
                        message: data.message,
                        delay: 5000,
                    });
                } else {
                    alert({ message: data.message });
                }
            },
            error: () => {
                form.spinner().stop();
                alert(errorMsg);
            },
        });
        return false;
    });
}

function animateElements() {
    const mqTablet = `(min-width: ${breakpoints.md + 1}px) and (max-width: ${breakpoints.lg}px)`;
    const mqDesktop = `(min-width: ${breakpoints.lg + 1}px)`;
    const mqObj = {};

    mqObj[mqTablet] = function () {
        pinVineyardHeading(0);
    };

    mqObj[mqDesktop] = function () {
        pinVineyardHeading(80);
    };

    ScrollTrigger.matchMedia(mqObj);
}

function init() {
    animateElements();
    handleForms();
}

module.exports = {
    init,
};
