let $recipes;

function filterRecipes(filterId) {
    $recipes
        .hide()
        .filter((i, el) => $('.culinary-recipe-item', el).data('category').includes(filterId))
        .show();
}

function handleFilterAllClick() {
    $recipes.show();
}

function handleFilterClick(e) {
    const $this = $(e.currentTarget);

    filterRecipes($this.data('id'));
}

function handleSelectChange(e) {
    const $this = $(e.currentTarget);
    const filterId = $this.val();

    if (filterId === 'all') {
        handleFilterAllClick();
    } else {
        filterRecipes(filterId);
    }
}

function createSelectElement() {
    const $filters = $('.js-culinary-filter .btn');
    const $formSelect = $(`
        <div class="form-select culinary__filter-select d-md-none">
            <select class="w-100">
                <option value="all" selected>All Recipes</option>
            </select>
        </div>`);
    const $select = $('select', $formSelect);

    $filters.each((i, el) => {
        $select.append(`<option value="${el.dataset.id}">${el.innerText}</option>`);
    });

    $('.culinary__filters').before($formSelect);
    $select.on('change', handleSelectChange);
}

function bindEvents() {
    $('.js-culinary-filter .btn').on('click', handleFilterClick);
    $('.js-culinary-filter-all').on('click', handleFilterAllClick);
}

function getAllRecipes() {
    $recipes = $('.js-recipe-item');
}

module.exports = {
    getAllRecipes,
    bindEvents,
    createSelectElement,
};
