function handleSelectChange(e) {
    const $this = $(e.currentTarget);
    const url = $this.val();

    if (!url) {
        return;
    }

    document.location.href = url;
}

function createSelectElement(target) {
    const $target = $(target);
    const $items = $target.find('li a');
    const $formSelect = $(`
        <div class="form-select experiences__select d-xl-none">
            <select class="w-100">
                <option value="" disabled selected>Select Experience</option>
            </select>
        </div>`);
    const $select = $('select', $formSelect);

    $items.each((i, el) => {
        $select.append(`<option value="${el.href}">${el.text}</option>`);
    });

    $target.before($formSelect);
    $select.on('change', handleSelectChange);
}

function init() {
    const $listItems = $('.js-experiences-categories');

    $listItems.each((i, el) => {
        createSelectElement(el);
    });
}

module.exports = {
    init,
};
