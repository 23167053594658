const { gsap } = require('gsap/dist/gsap');
const { ScrollTrigger } = require('gsap/dist/ScrollTrigger');
const { breakpoints } = require('site/components/functions');

gsap.registerPlugin(ScrollTrigger);

let tl;

function animateImage(target, mq) {
    const yPos = mq === 'small' ? '10%' : '40%';
    const $img = target.find('img');

    if (tl) {
        tl.kill();
    }

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: target,
            scrub: true,
            start: 'top bottom',
            end: `bottom+=${yPos} bottom`,
        },
    });

    tl.fromTo($img, { y: yPos }, { y: '0%', ease: 'power1.inOut' });
}

function animateElements(target) {
    if (!target.length || target.hasClass('animation-initialized')) {
        return;
    }

    const mqSmall = `(max-width: ${breakpoints.xl - 1}px)`;
    const mqLarge = `(min-width: ${breakpoints.xl}px)`;
    const mqObj = {};

    mqObj[mqSmall] = animateImage.bind(null, target, 'small');
    mqObj[mqLarge] = animateImage.bind(null, target, 'large');

    ScrollTrigger.matchMedia(mqObj);
    target.addClass('animation-initialized');
}

function init() {
    $('.js-footer-image').each((i, el) => {
        const $el = $(el);
        if (!$el.hasClass('animation-initialized')) {
            animateElements($el);
        }
    });
}

module.exports = {
    init,
};
