const { breakpoints } = require('site/components/functions');
const { animateArrows } = require('site/components/carouselAnimations');

module.exports = function () {
    $('.js-slider-gallery').each((i, el) => {
        const $this = $(el);

        const slickOptions = {
            mobileFirst: true,
            infinite: true,
            slidesToScroll: 1,
            centerMode: true,
            dots: true,
            slidesToShow: 1,
            centerPadding: '15%',
            prevArrow: '<div class="d-none d-md-block arrow__container arrow__container--prev"><span class="arrow__diamond"></span><button type="button" class="slick-prev">Previous</button></div>',
            nextArrow: '<div class="d-none d-md-block arrow__container arrow__container--next"><span class="arrow__diamond"></span><button type="button" class="slick-next">Next</button></div>',
            responsive: [
                {
                    breakpoint: breakpoints.md,
                    settings: {
                        dots: false,
                        centerPadding: '22.5%',
                    },
                },
                {
                    breakpoint: breakpoints.xl,
                    settings: {
                        dots: false,
                        centerPadding: '22.5%',
                    },
                },
            ],
        };

        $this.slick(slickOptions);
        animateArrows($this);
    });
};
