const processInclude = require('base/util');

$(document).ready(() => {
    // Anniversary
    processInclude(require('./experience/components/anniversary/anniversaryNavbar'));
    processInclude(require('./experience/components/anniversary/anniversarySeries'));

    // Assets
    processInclude(require('./experience/components/assets/membershipTile'));

    // Dynamic
    processInclude(require('./experience/components/dynamic/dynamicAccolades'));
    processInclude(require('./experience/components/dynamic/dynamicProductPairings'));

    // Omni Assets
    processInclude(require('./experience/components/omni_assets/footerImage'));
    processInclude(require('./experience/components/omni_assets/heroBanner'));
    processInclude(require('./experience/components/omni_assets/mountainsSection'));

    // Omni Layouts
    processInclude(require('./experience/components/omni_layouts/culinary'));
    processInclude(require('./experience/components/omni_layouts/experienceList'));
    processInclude(require('./experience/components/omni_layouts/headlineAndImages'));
    processInclude(require('./experience/components/omni_layouts/headlineAndListItems'));
    processInclude(require('./experience/components/omni_layouts/homepageHighlights'));
    processInclude(require('./experience/components/omni_layouts/ourVineyardsDetails'));
    processInclude(require('./experience/components/omni_layouts/sliderGallery'));

    // Regular Components
    processInclude(require('./product/productTile'));
});
