const { breakpoints } = require('site/components/functions');

function init() {
    $(window).on('load resize orientationchange', () => {
        $('.js-accolades-slick').each((i, el) => {
            const $slider = $(el);
            const isInitialized = $slider.hasClass('slick-initialized');
            const isMd = $(window).width() > breakpoints.md;

            if (!isInitialized && !isMd) {
                $slider.slick({
                    adaptiveHeight: true,
                    arrows: false,
                    dots: true,
                    mobileFirst: true,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                });
            } else if (isInitialized && isMd) {
                $slider.slick('unslick');
            }
        });
    });
}

module.exports = {
    init,
};
